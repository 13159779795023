<template>
  <div class="not-found">
    <div class="not-found__container">
      <div class="d-flex">
        <div class="not-found__illustration">
          <img
            alt="direction icon"
            src="../assets/svg/direction.svg"
            v-svg-inline
          />
        </div>
        <div class="not-found__text-container">
          <div class="not-found__title">404</div>
          <div class="not-found__text">Oops, it seems we got lost!</div>
        </div>
      </div>
      <router-link to="/" class="not-found__button">
        Back to my bar!
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "not-found-page",
};
</script>
